import { render, staticRenderFns } from "./Tools.vue?vue&type=template&id=d83bbb5e&"
import script from "./Tools.vue?vue&type=script&lang=js&"
export * from "./Tools.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSectionHeader: require('/var/www/html/preview/components/home/SectionHeader.vue').default,ContentfulImage: require('/var/www/html/preview/components/contentful/Image.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default})
